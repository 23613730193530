@font-face {
  font-family: "Arima Madurai";
  src: url("../fonts/ArimaMadurai-Bold.eot");
  src: url("../fonts/ArimaMadurai-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/ArimaMadurai-Bold.woff2") format("woff2"),
    url("../fonts/ArimaMadurai-Bold.woff") format("woff"),
    url("../fonts/ArimaMadurai-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arima Madurai";
  src: url("../fonts/ArimaMadurai-Black.eot");
  src: url("../fonts/ArimaMadurai-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/ArimaMadurai-Black.woff2") format("woff2"),
    url("../fonts/ArimaMadurai-Black.woff") format("woff"),
    url("../fonts/ArimaMadurai-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Regular.eot");
  src: url("../fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Nunito-Regular.woff2") format("woff2"),
    url("../fonts/Nunito-Regular.woff") format("woff"),
    url("../fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Medium.eot");
  src: url("../fonts/Nunito-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Nunito-Medium.woff2") format("woff2"),
    url("../fonts/Nunito-Medium.woff") format("woff"),
    url("../fonts/Nunito-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-SemiBold.eot");
  src: url("../fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Nunito-SemiBold.woff2") format("woff2"),
    url("../fonts/Nunito-SemiBold.woff") format("woff"),
    url("../fonts/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-ExtraBold.eot");
  src: url("../fonts/Nunito-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Nunito-ExtraBold.woff2") format("woff2"),
    url("../fonts/Nunito-ExtraBold.woff") format("woff"),
    url("../fonts/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Orbitron';
  src: url('../fonts/Orbitron-Black.eot');
  src: url('../fonts/Orbitron-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Orbitron-Black.woff2') format('woff2'),
      url('../fonts/Orbitron-Black.woff') format('woff'),
      url('../fonts/Orbitron-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbitron';
  src: url('../fonts/Orbitron-Bold.eot');
  src: url('../fonts/Orbitron-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Orbitron-Bold.woff2') format('woff2'),
      url('../fonts/Orbitron-Bold.woff') format('woff'),
      url('../fonts/Orbitron-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbitron';
  src: url('../fonts/Orbitron-Regular.eot');
  src: url('../fonts/Orbitron-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Orbitron-Regular.woff2') format('woff2'),
      url('../fonts/Orbitron-Regular.woff') format('woff'),
      url('../fonts/Orbitron-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbitron';
  src: url('../fonts/Orbitron-SemiBold.eot');
  src: url('../fonts/Orbitron-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Orbitron-SemiBold.woff2') format('woff2'),
      url('../fonts/Orbitron-SemiBold.woff') format('woff'),
      url('../fonts/Orbitron-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbitron';
  src: url('../fonts/Orbitron-Medium.eot');
  src: url('../fonts/Orbitron-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Orbitron-Medium.woff2') format('woff2'),
      url('../fonts/Orbitron-Medium.woff') format('woff'),
      url('../fonts/Orbitron-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbitron';
  src: url('../fonts/Orbitron-ExtraBold.eot');
  src: url('../fonts/Orbitron-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Orbitron-ExtraBold.woff2') format('woff2'),
      url('../fonts/Orbitron-ExtraBold.woff') format('woff'),
      url('../fonts/Orbitron-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Thin.eot');
  src: url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Thin.woff2') format('woff2'),
      url('../fonts/Roboto-Thin.woff') format('woff'),
      url('../fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Black.eot');
  src: url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Black.woff2') format('woff2'),
      url('../fonts/Roboto-Black.woff') format('woff'),
      url('../fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Italic.eot');
  src: url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Italic.woff2') format('woff2'),
      url('../fonts/Roboto-Italic.woff') format('woff'),
      url('../fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Medium.woff2') format('woff2'),
      url('../fonts/Roboto-Medium.woff') format('woff'),
      url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light.eot');
  src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Light.woff2') format('woff2'),
      url('../fonts/Roboto-Light.woff') format('woff'),
      url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Regular.woff2') format('woff2'),
      url('../fonts/Roboto-Regular.woff') format('woff'),
      url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-Bold.woff2') format('woff2'),
      url('../fonts/Roboto-Bold.woff') format('woff'),
      url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-SemiBold.eot');
  src: url('../fonts/Roboto-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto-SemiBold.woff2') format('woff2'),
      url('../fonts/Roboto-SemiBold.woff') format('woff'),
      url('../fonts/Roboto-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

